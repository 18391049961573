@keyframes fadeInDetails {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);

    opacity: 1;
  }
}

@keyframes onHold {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.photoList {
  display: flex;
  margin: 0 0 2em 0;
  flex-wrap: wrap;
}

.photoThumb {
  margin: 0.5em;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border: 10px solid white;
  padding: 8px;
  border: 1px solid #d9d9d9;
  position: relative;
  cursor: pointer;

  &:active:not(.-isMain) {
    &::before {
      animation: onHold 1.2s;
    }
  }

  &::before {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--primary);
    opacity: 0.2;
    content: "";
    width: 100%;
    height: 0px;
    z-index: 1;
  }

  &.-faild {
    border: red 1px solid;
    box-shadow: inset 0 0 5px 0px rgba(255, 0, 0, 0.4);
    background: rgba(255, 0, 0, 0.1);

    img {
      filter: grayscale(100%);
      opacity: 0.3;
    }
  }

  &.-isMain {
    border-color: var(--primary);
    &::before {
      height: 100%;
      opacity: 0.3;
    }
  }

  > img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__options {
    z-index: 3;
    overflow: hidden;
    width: 30px;
    height: 30px;
    color: white;
    transition: 0.3s;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: 50px;
    background: rgba(black, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.-removing {
      width: 100px;
      height: 100px;
      top: 0;
      right: 0;
      border-radius: 0;

      .loadingText,
      .undoBtn {
        display: block;
        animation: fadeInDetails 1s forwards;
      }
    }

    &:hover:not(.-removing) {
      background: rgba(black, 1);

      button {
        color: red;

        &.-reUpload {
          color: #00ff00;
        }
      }
    }

    > button:first-child {
      transition: 0.3s;
      width: 100%;
      height: 100%;
      border: 0;
      cursor: pointer;
      outline: 0;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loadingText {
      font-size: 10px;
      display: none;
      margin: 10px 0;
      opacity: 1;
    }

    .undoBtn {
      display: none;
      opacity: 1;
      font-size: 10px;
      background-color: white;
      border-radius: 15px;
      transition-delay: 2s;
      color: black;
      border: 0;
      outline: none;
      padding: 2px 8px;
      cursor: pointer;
      span:first-child {
        margin-left: 5px;
      }
    }
  }

  &__uploading {
    width: 84px;
    height: 84px;
    background: rgba(whitesmoke, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 3;
    > span {
      &:first-child {
        margin-bottom: 10px;
        color: var(--primary);
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
}

.photoModal {
  .photoSlide {
    img {
      width: 100%;
      max-height: 500px;
      object-fit: contain;
    }
  }

  .slick-track {
    cursor: move;
  }
  .slick-dots {
    li {
      button {
        background: var(--primary) !important;
        border: 1px solid white !important;
        height: 10px;
      }
    }
  }
}
