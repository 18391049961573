.reactPlacesAutocomplete {
  width: 100%;
  &__input {
  }

  &__results {
    margin-top: 2em;
    border-radius: 5px;

    &:not(:empty) {
      background-color: white;
      border: 1px solid var(--border-primary);
    }

    > .results__item {
      height: 3.5em;
      display: flex;
      align-items: center;
      padding: 0 2em;
      border-right: 5px solid transparent;

      &.-active {
        border-right: 5px solid var(--primary);
        background-color: var(--bg-light-primary);
      }
    }
  }
}
