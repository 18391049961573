:root {
  --primary: #ff9100;
  --dark: #3c3442;
  --light: #fff;

  --text-light-second: rgba(#fff, 0.45);
  --border-primary: #e9e9e9;

  --bg-first: #f0f2f5;
  --bg-light: #fff;

  --bg-light-primary: rgba(#ff9100, 0.1);
}
