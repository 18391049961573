@import "./../../styles/config/mixins.less";

.posts {
  &__tabs {
    background-color: var(--bg-light);
    .ant-table-wrapper-rtl .ant-table-selection {
      text-align: right !important;
    }
  }

  .badge-primary {
    margin-left: 0.5em;
    margin-bottom: 0.3em;
    > sup {
      background: var(--primary);
    }
  }

  .ant-tabs-nav {
    padding: 0 1em;
    .breakpoint-sm({
      flex-wrap: wrap-reverse;
      justify-content: space-between;
    });

    .ant-tabs-nav-wrap {
      direction: ltr !important;
      flex-direction: row-reverse;
    }
  }

  .post-images {
    cursor: pointer;

    .breakpoint-sm({
       
       flex-wrap: wrap;
     });

    &__img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 5px;
      margin: 8px 0;
    }
    &__more {
      width: 50px;
      height: 50px;
      margin: 8px 0;
      border: 3px solid var(--primary);
      border-radius: 5px;
      font-size: 20px;
      color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      direction: ltr !important;
      background: rgba(#ff9100, 0.2);
    }
  }

  .operations {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .call-to-action {
    color: var(--primary);
  }
}

.singlePost {
  // padding: 1.5em;
  .shop {
    background-color: white;
    padding: 2em;
    margin-bottom: 1rem;
    &__header {
      display: flex;
      justify-content: space-between;

      b {
        font-size: 1rem;
      }
    }

    .ant-collapse-header,
    .ant-collapse-content-box {
      padding: 1rem 0 !important;
    }

    .shopPanelContent {
      display: flex;
      width: 100%;
      align-items: flex-start;
      .breakpoint-md({
        flex-direction: column
      });

      .shopSearch {
        width: 50%;
        margin-left: 2rem;

        .breakpoint-md({
          width: 100%;
          margin-bottom: 1rem
      });

        b {
          display: flex;
          font-weight: bold;
          margin-bottom: 2rem;
          font-size: 1rem;
        }
      }

      .loadCard {
        flex: 1;
        background: #efefef;
        border-radius: 3px;
        padding: 2.5rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #222;

        .breakpoint-md({
          width: 100%
      });

        b {
          margin-right: 1rem;
        }
      }
      .shopCard {
        flex: 1;
        background: #efefef;
        border-radius: 3px;
        padding: 1rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #222;

        .breakpoint-md({
          width: 100%
      });

        img {
          width: 75px;
          height: 75px;
          border-radius: 3rem;
          margin-left: 1rem;
        }

        > div {
          display: flex;
          flex-direction: column;
          flex: 1;
          strong {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .container {
    background-color: white;
    padding: 2em;

    .phoneField {
      input {
        direction: ltr !important;
        text-align: right;
      }
    }

    .ant-form-item {
      display: flex;
      flex-direction: row;

      .breakpoint-sm({
        flex-direction: column;
      });

      .ant-form-item-control {
        flex: 1;
        margin-right: 2em;
        .breakpoint-xs({
          margin-right: 0;
        });
      }
      .ant-form-item-label {
        width: 8em;
        margin-right: 2em;
        text-align: right;
        .breakpoint-xs({
          margin-right: 0;
          width: 100%;
        });
      }
    }
  }
}
.singlePost__loading {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
