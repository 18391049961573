.header-profile {
  display: flex;
  align-items: center;
  margin-right: 2em;
  > span:not(:last-child) {
    margin-left: 10px;
  }
  .anticon {
    // margin-right: 4px;
    margin-top: 4px;
    opacity: 0.5;
  }
}
