.field {
  width: 100%;
  textarea.ant-input {
    max-height: 20em;
    min-height: 10em;
  }
  &.-multiLine {
    min-height: 10em;
  }

  &.-column {
    .ant-form-item {
      flex-direction: column !important;
      .ant-form-item-label {
        width: auto !important;
      }
    }
  }
}
