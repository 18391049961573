.mapField {
  position: relative;
  &__search {
    width: 50%;
    position: absolute;
    top: 2em;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
  }
}
