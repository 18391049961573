@import "./../../../../../styles/config/mixins.less";

.contactFieldGenerator {
  width: 100%;
  margin-top: 3em;
  border-top: 1px dashed #dfdfdf;
  padding-top: 3rem;
  padding-bottom: 5rem;

  &__title {
    padding-right: 2em;
    margin-bottom: 2em !important;

    .breakpoint-xs({
        padding-right: 0;
    });
  }

  .addFieldContainer {
    padding-right: 12em;
    // margin-top: 2em;

    .breakpoint-sm({
        padding-right: 2em;
    });
    .breakpoint-xs({
        padding-right: 0;
    });
  }
  .removeField {
    display: flex;
    justify-content: flex-end;

    .breakpoint-xs({
        justify-content: flex-start;
        flex: 1
    });
  }
}
