@font-face {
  font-family: "NRT";
  src: url("NRTBold-Bold.eot");
  src: url("NRTBold-Bold.eot?#iefix") format("embedded-opentype"),
    url("NRTBold-Bold.woff2") format("woff2"),
    url("NRTBold-Bold.woff") format("woff"),
    url("NRTBold-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NRT";
  src: url("NRTReg.eot");
  src: url("NRTReg.eot?#iefix") format("embedded-opentype"),
    url("NRTReg.woff2") format("woff2"), url("NRTReg.woff") format("woff"),
    url("NRTReg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
