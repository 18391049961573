.sidebar {
  background: var(--dark);
  min-height: 100vh;
  padding: 1.5em 0;

  &__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;

    img {
      width: 60%;
      height: 2em;
    }
  }

  &__menu {
    background-color: transparent !important;
  }
}
