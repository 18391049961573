@import "./config/mixins.less";

* {
  direction: rtl !important;
}

html {
  font-size: 100%;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
}
body {
  // font-family: var(--font-first), sans-serif;
  // color: var(--text-color-first);
  font-weight: 400;
  // background: var(--color-first);
  // direction: rtl;
  min-height: 100vh;
  line-height: 1;
  overflow-x: hidden;
  text-align: right;
}
.page-wrapper {
  width: 100%;
  .site-page-header {
    .breakpoint-sm({
      padding: 1em;
    });

    .ant-page-header-heading-extra {
      .breakpoint-sm({
        width: 100%;
        > a, > button {
          width: 100%;
        }
      });
    }

    background-color: var(--light);
  }

  .page {
    transition: 0.5s;
    padding: 20px;
    &.fullscreen {
      padding: 0;
    }
    // padding-top: 20px;
  }
}

.table-row-expanded-card {
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1 50%;
    // display: flex;
    align-items: center;
    min-height: 70px;
    border-color: var(--border-primary) !important;

    &:not(:nth-last-child(2)):not(:last-child),
    &:nth-child(2) {
      border-bottom: 1px solid;
    }

    &:nth-child(2n) {
      padding-right: 15px;
      border-right: 1px solid;
    }

    .breakpoint-sm({
      flex: 1 100%;
      border-right: 0 !important;
      padding-right: 0 !important;
      &:not(:last-child){
        border-bottom: 1px solid var(--border-primary)  !important;
      }
    });
  }
}

.ant-badge > sup {
  direction: ltr !important;
}

.otpInput {
  flex-direction: row-reverse;
  width: 3em;
  padding: 0.1em;
  input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100% !important;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;

    &:focus {
      border-color: var(--primary);
      outline: none !important;
    }
  }
}

.ltr-field {
  direction: ltr !important;
  text-align: right;
}

.site-layout-content {
  background: #fff;
}

.map {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  border: 1px solid var(--border-primary);
  overflow: hidden;
  // .breakpoint-xs({
  //   height: 200px;
  //   border-radius: 0px;
  // });
}

.map .map__label {
  background-color: var(--dark);
  border-radius: 100px;
  padding: 0.5rem 1rem;
  color: white;
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
}

.map .map__label::before {
  content: "";
  width: 0;
  height: 0;
  content: 0;
  border: 10px transparent solid;
  border-bottom: 10px solid var(--dark);
  position: absolute;
  bottom: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%);
}
