//customize bootstrap styles and variables
//variables

@import "./config/mixins.less";

@primary-color: #ff9100;
@font-family: "NRT", sans-serif;

body {
  text-align: unset !important;
  padding: 0;
}
.ant-menu {
  text-align: right;
  .ant-menu-submenu-arrow {
    left: 16px;
    right: unset !important;
  }
  .anticon {
    margin-right: 0;
    margin-left: 10px;
  }
}

.ant-page-header-back {
  margin-right: 0;
  margin-left: 16px;
}
.ant-input-prefix {
  margin-right: 0;
  margin-left: 4px;
}

.ant-table {
  td,
  th {
    text-align: right !important;
  }
}

.ant-tag {
  margin-right: 0;
  margin-left: 8px;
}
.ant-space-item:not(:last-child) {
  margin-right: 0 !important;
  margin-left: 16px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-left: 0;
  margin-right: 8px;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}

.ant-steps-item {
  .breakpoint-xs({
    padding-left: 0 !important;
  });
}
.ant-steps-item-title::after {
  left: unset;
  right: 100%;
}

.ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}

.ant-steps-item-icon {
  margin: 0 8px;
}

.ant-modal {
  .breakpoint-xs({
    top : 0 !important;
    margin : 0 !important;
    max-width: 100% !important;
    padding: 0 !important;
    min-height: 100% !important;
    > .ant-modal-content {
      height: 100% !important;
    }
  });
}

.ant-modal-close {
  right: unset;
  left: 0;
}

.ant-dropdown-menu-item {
  text-align: right;
}

.ant-pagination,
.ant-pagination *,
.ant-carousel,
.ant-carousel * {
  direction: ltr !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: black !important;
  font-size: 12px !important;
}

.ant-input-number-handler-wrap {
  left: 0;
  right: unset;
  border-right: 1px solid #d9d9d9;
  border-left: none;
}

.ant-input-number-input {
  text-align: right;
}

.ant-card-meta-avatar {
  float: right;
  padding-left: 16px;
  padding-right: unset;
}

.ant-notification-notice-close {
  left: 22px;
  right: unset;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: unset;
  margin-right: 45px;
  padding-right: 0;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0;
  margin-right: 8px;
}
// .ant-menu-inline .ant-menu-submenu-title {
//   padding-left: 34px !important;
//   padding-right: 17px !important;
// }

.ant-upload-list-picture-card-container {
  float: right;
  margin: 0 0 8px 8px;
}

.ant-form-item-control-input-content > *:only-child {
  width: 100%;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  .breakpoint-sm ({
    padding-right: 0
  });;
}

.ant-picker-time-panel .ant-picker-content {
  flex-direction: row-reverse;
}
