.images-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  &__carousel {
    width: calc(100% - 100px);

    .modal-image {
      height: 400px;
      object-fit: contain;
    }
  }
}
