@import "./../../styles/config/mixins.less";

.fields {
  background: #fff;

  .column-action {
    display: flex;
    justify-content: flex-end;
  }
  .table__title {
    display: flex;
    align-items: center;
    .table__title-type {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin: 0 0.5rem;
      display: flex;
    }
    .table__title-type.new {
      background: #00bb00;
    }
    .table__title-type.removed {
      background: #f26267;
    }
    .table__title-type.updated {
      background: #ff9100;
    }
  }

  .action-wrapper {
    display: flex;
    align-items: center;

    .call-to-action {
      display: flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
      }
    }
  }
}

.category-modal,
.attribute-modal,
.option-modal {
  .ant-form-item {
    display: flex;
    flex-direction: row;
    .ant-form-item-control {
      flex: 1;
      margin-right: 2em;
    }
    .ant-form-item-label {
      width: 8em;
      margin-right: 2em;
      text-align: right;
    }
  }
}

.FieldsTable {
  &__header {
    display: flex;
    align-items: center;
    padding: 1.5em;

    .breakpoint-xs({
      padding:  1em;
    });

    > div {
      .breakpoint-xs({
        flex: 1 200px;
      });

      &:first-child {
        .breakpoint-xs({
          margin-left: 1em;
          margin-bottom: 1em;
        });

        @media (max-width: 460px) {
          margin-left: 0;
        }
      }
    }

    > div:last-child {
      > div {
        width: 100%;
        > div {
          flex: 1;
          > button {
            width: 100%;
          }
        }
      }
    }
  }
}

.fields__row-dragging {
  background: #fafafa;
  border: 1px solid var(--primary);

  td {
    padding: 16px;
    visibility: hidden;
  }
  .drag-visible {
    visibility: visible;
  }
}
