//extra large
.breakpoint-xl( @content) {
  @media (min-width: 1200px) {
    @content();
  }
}

//large
.breakpoint-lg(@content ; @limited : false) when(@limited  = true) {
  @media (max-width: 1200px) and (min-width: 992px) {
    @content();
  }
}
.breakpoint-lg(@content;  @limited : false) when(@limited  = false) {
  @media (max-width: 1200px) {
    @content();
  }
}

//medium
.breakpoint-md(@content ; @limited : false) when(@limited  = true) {
  @media (max-width: 992px) and (min-width: 720px) {
    @content();
  }
}
.breakpoint-md(@content;  @limited : false) when(@limited  = false) {
  @media (max-width: 992px) {
    @content();
  }
}

//small
.breakpoint-sm(@content ; @limited : false) when(@limited  = true) {
  @media (max-width: 768px) and (min-width: 576px) {
    @content();
  }
}
.breakpoint-sm(@content;  @limited : false) when(@limited  = false) {
  @media (max-width: 768px) {
    @content();
  }
}

//extra small
.breakpoint-xs(@content ) {
  @media (max-width: 576px) {
    @content();
  }
}
