.uploadBtn {
  margin: 0.5em;
  width: 100px;
  height: 100px;
  border: 2px dashed #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  padding: 8px;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  overflow: hidden;

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__input {
    position: absolute;
    top: -100%;
    right: -100%;
    opacity: 0;
  }

  span:first-child {
    font-size: 30px;
    transition: 0.3s;
  }

  &:hover {
    border-color: var(--primary);
    > span:first-child {
      transform: translateY(-5px);
      color: var(--primary);
    }
  }
}
