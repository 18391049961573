.singleUploader.-hasFormikError {
  border: 1px solid red;
}

.singleUploader {
  width: 100%;
  height: 10rem;
  border: 1px solid var(--border-primary);

  > * {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .previewBox {
    background: white;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: default;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__closeBtn {
      position: absolute;
      background: transparent;
      border: 0;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      width: 2rem;
      background: white;
      border: 1px solid var(--border-primary);
      height: 2rem;
      font-size: 0.785;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      pad: 1rem;
      box-shadow: 0 0 5px #666;

      > * {
        font-size: 1rem !important;
      }
    }
  }
  .loading,
  .error {
    display: flex;
    align-items: center;
    justify-content: center;

    > div:first-child > span {
      margin-right: 1rem;
    }
  }

  .error {
    flex-direction: column;
    background: rgba(255, 0, 0, 0.1);
    > span {
      font-weight: 700;
    }

    > div:first-child {
      margin-bottom: 1rem;
    }
  }
}
