@import "./../../../styles/config/mixins.less";

.my-label {
  display: none;

  &.show-in-lg {
    .breakpoint-lg({
        display: flex;
    }, true);
  }
  &.show-in-md {
    .breakpoint-md({
        display: flex;
    }, true);
  }

  &.show-in-sm {
    .breakpoint-sm({
        display: flex;
    }, true);
  }

  &.show-in-xs {
    .breakpoint-xs({
        display: flex;
    });
  }
}
