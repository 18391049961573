.pagination {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1em;
}

.pagination span {
  width: 85px;
  text-align: center;
}
