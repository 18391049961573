.header-locale {
  display: flex;
  align-items: center;
  margin-right: 2em;
  > span:not(:last-child) {
    margin-left: 10px;
  }
  .anticon {
    // margin-right: 4px;
    margin-top: 4px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
