@import "./../../../styles/config/mixins.less";

.map {
  width: 100%;
  height: 400px;
  border-radius: 20px;
  border: 1px solid var(--border-primary);
  overflow: hidden;
  .breakpoint-xs({
    height: 200px;
    border-radius: 0px;
  });
}

.map .map__label {
  background-color: var(--dark);
  border-radius: 100px;
  padding: 0.5rem 1rem;
  color: white;
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
}

.map .map__label::before {
  content: "";
  width: 0;
  height: 0;
  content: 0;
  border: 10px transparent solid;
  border-bottom: 10px solid var(--dark);
  position: absolute;
  bottom: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%);
}

/* remove license and logo */

.map .gmnoprint a,
.gmnoprint span {
  display: none;
}
.map .gmnoprint div {
  background: none !important;
}
.map div div a div img {
  display: none;
}
.map .gm-style-cc {
  display: none;
}

.map
  > div:first-child
  > div:only-child
  > div:only-child
  > div
  > div
  > a
  > div
  > img {
  display: none;
}
