@import "./../../../styles/config/mixins.less";

.header {
  background: var(--light);
  border-bottom: var(--border-primary) 1px solid;
  display: flex;
  align-items: center;
  width: 100%;

  .breakpoint-sm({
    padding: 1em;
  });

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
  }
}
