.staff {
  &__table {
    background-color: var(--bg-light);
  }
}

.phone__field {
  direction: ltr !important;
  text-align: right;
}

.add-staff-modal {
  .steps-content {
    margin-top: 50px;
  }
}
