.login {
  height: 100vh;
  background-color: var(--bg-first);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  .row {
    height: 100%;
    .space {
      width: 100%;
      justify-content: center;
      header {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        img {
          width: 200px;
        }
      }
    }

    .otpStep {
      display: flex;
      flex-direction: column;
      align-items: center;
      .otpInputField > div > div > div > div {
        flex-direction: row-reverse;
      }
    }

    input[name="phoneRegion"] {
      direction: ltr !important;
    }

    .loginLoading {
      padding: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed var(--primary);
      opacity: 0.5;
    }
  }
}
