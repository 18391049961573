@import "./../../styles/config/mixins.less";

.shops {
  flex: 1;
  &__tabs {
    background-color: var(--bg-light);
    .ant-table-wrapper-rtl .ant-table-selection {
      text-align: right !important;
    }
  }

  .badge-primary {
    margin-left: 0.5em;
    margin-bottom: 0.3em;
    > sup {
      background: var(--primary);
    }
  }

  .ant-tabs-nav {
    padding: 0 1em;
    .breakpoint-sm({
      flex-wrap: wrap-reverse;
      justify-content: space-between;
    });

    .ant-tabs-nav-wrap {
      direction: ltr !important;
      flex-direction: row-reverse;
    }
  }

  .shops-images {
    cursor: pointer;

    .breakpoint-sm({
       
       flex-wrap: wrap;
     });

    &__img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 5px;
      margin: 8px 0;
    }
    &__more {
      width: 50px;
      height: 50px;
      margin: 8px 0;
      border: 3px solid var(--primary);
      border-radius: 5px;
      font-size: 20px;
      color: var(--primary);
      display: flex;
      align-items: center;
      justify-content: center;
      direction: ltr !important;
      background: rgba(#ff9100, 0.2);
    }
  }

  .operations {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .call-to-action {
    color: var(--primary);
  }
}

.singleShop .container {
  background: #fff;
  padding: 2em;

  .phoneField {
    input {
      direction: ltr !important;
      text-align: right;
    }
  }

  .fillInMobileView {
    .breakpoint-xs({      
      margin: 0 -2em;
      .ant-form-item-label {
       padding-right: 2em;
     }
    });
  }

  .ant-form-item {
    display: flex;
    flex-direction: row;

    .breakpoint-sm({
      flex-direction: column;
    });

    .ant-form-item-control {
      flex: 1;
      margin-right: 2em;
      .breakpoint-xs({
        margin-right: 0;
      });
    }
    .ant-form-item-label {
      width: 8em;
      margin-right: 2em;
      text-align: right;
      .breakpoint-xs({
        margin-right: 0;
        width: 100%;
      });
    }
  }
}
