// .loading-view {
//   width: 100vw;
//   height: 100vh;
//   overflow: hidden;
//   display: flex;
//   justify-items: center;
//   align-items: center;
//   padding: 20px !important;

//   .loading-skeleton {
//     width: 100% !important;
//     height: 100% !important;
//   }

//   &__sidebar {
//     height: calc(100vh - 40px);
//     width: 250px;
//     border-radius: 5px;
//     margin-left: 20px;
//   }
//   &__content {
//     height: 100%;
//     flex: 1;
//     display: flex;
//     flex-direction: column;

//     > header {
//       background: #efefef;
//       border-radius: 5px;
//       height: 80px;
//     }

//     .body {
//       height: calc(100% - 100px);
//       margin-top: 20px;
//       flex: 1;
//       display: flex;
//       &__aside {
//         width: 250px;
//         height: 100%;
//       }
//       &__article {
//         flex: 1;
//         margin-left: 20px;
//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         > header {
//           display: flex;
//           width: 100%;
//           height: 150px;
//           margin-bottom: 20px;

//           > div {
//             flex: 1;
//             height: 150px;
//             &:not(:last-child) {
//               margin-left: 20px;
//             }
//           }
//         }

//         > img {
//           width: 500px;
//           filter: grayscale(100%);
//         }

//         > div {
//           flex: 1;
//           width: 100%;

//           .message {
//             height: 80px;
//             margin-top: 20px;
//             width: 100%;
//             display: flex;
//             position: relative;

//             &__text {
//               position: absolute;
//               color: #999;
//               left: 50%;
//               top: 50%;
//               transform: translate(-50%, -50%);
//             }
//           }
//         }
//       }
//     }
//   }
// }

.loading-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &__spinner {
    color: #999;
    font-size: 4rem;
  }
}
